import { LocationOn, Mail, Phone } from '@mui/icons-material';
import { Avatar, Box, Button, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { ProjectContext } from './openProject';
import Map from './map';

function ClientInfo(props) {
    const projectContext = useContext(ProjectContext);
    const { project } = projectContext;
    const { contact } = project;

    const { hideClient } = props;

    function mapsSelector() {
         /* if we're on iOS, open in Apple Maps */
        if((navigator.platform.indexOf("iPhone") != -1) || 
           (navigator.platform.indexOf("iPad") != -1) || 
           (navigator.platform.indexOf("iPod") != -1))
          window.open(`maps://maps.google.com/maps?daddr=${project.salesToOps.coordinates.lat},${project.salesToOps.coordinates.lng}&amp;ll=`);
        else /* else use Google */
          window.open(`https://maps.google.com/maps?daddr=${project.salesToOps.coordinates.lat},${project.salesToOps.coordinates.lng}&amp;ll=`);
    }

    return (
        <Box sx={{bgcolor: 'white', width: '100%', height: '100vh'}}>
            <Button
                sx={{fontSize: '12px', mt: 1, ml: 1}}
                onClick={hideClient}
            >
                back to project    
            </Button>

            <Box sx={{width: '100%', textAlign: 'center', mt: 1, mb: 4}}>
                <Avatar alt={contact.name} src='../../images' sx={{height: '70px', width: '70px', mx: 'auto', bgcolor: '#0b56a4', mb: 2}} />

                <Typography variant='h4'>{contact.name}</Typography>

                <Typography variant='subtitle1' sx={{mb: 2, color: 'rgba(0,0,0,.75)'}}>{project.clientType} Client</Typography>

                <Button
                    sx={{fontSize: '14px', border: '1px solid #ed6a22', bgcolor: '#ed6a22', color: 'white', borderRadius: '50px 50px 50px 50px', px: 5}}
                    variant='contained'
                    component='a'
                    href={`tel:+${project.salesToOps?.projectDetails?.phone.replace(/[^A-Z0-9]/ig, "")}`}
                >
                    call customer
                </Button>
                <br />

                <Button
                    onClick={mapsSelector}
                    sx={{fontSize: '14px', border: '1px solid #ed6a22', color: '#ed6a22', borderRadius: '50px 50px 50px 50px', px: 5, mt: 1}}
                    variant='outlined'
                    disabled={!project.salesToOps.coordinates}
                >
                    get directions
                </Button>
            </Box>

            <Box sx={{width: '100%', px: 5}}>
                <Typography 
                    variant='subtitle1'
                    sx={{mb: 1, color: 'rgba(0, 0, 0, 0.65)'}}
                >
                    <Mail sx={{verticalAlign: 'middle', color: '#0b56a4', mr: 1}} /> {contact.email}
                </Typography>
                <Typography 
                    variant='subtitle1'
                    sx={{mb: 1, color: 'rgba(0, 0, 0, 0.65)'}}
                >
                    <Phone sx={{verticalAlign: 'middle', color: '#0b56a4', mr: 1}} /> {contact.phone}
                </Typography>

                <Typography 
                    variant='subtitle1'
                    sx={{mb: 3, color: 'rgba(0, 0, 0, 0.65)'}}
                >
                    <LocationOn sx={{verticalAlign: 'middle', color: '#0b56a4', mr: 1}} /> {contact.address}
                </Typography>
            </Box>

            {project.salesToOps.coordinates &&
                <Map />
            }
        </Box>
    )
}

export default ClientInfo;