import { Circle, Filter1, Filter2, Filter3, Filter4, Filter5, Filter6, Filter7, Filter8, Filter9, Filter9Plus, Image, Translate } from "@mui/icons-material";
import { Box, Button, Grid, ImageList, ImageListItem, ImageListItemBar, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { ProjectContext } from "./openProject";

function OpenLog(props) {
    const projectContext = useContext(ProjectContext);
    const {project} = projectContext;
    const { log, hideLog } = props;
    const { images, notes, date, user, title } = log;
    const [displayCarousel, setDisplayCarousel] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const [day, setDay] = useState(new Date(date).getDate()-1);

    let days = [
        'Sunday', 'Monday', 'Tuesday','Wednesday', 'Thursday', 'Friday', 'Saturday'
    ]

    useEffect(() => {
        if(day.toString().length === 1) {
            setDay(`0${day}`)
        }
    }, [])

    let style = {
        color: 'white'
    }

    let imageIcons = [
        <Filter1 sx={style} />, <Filter2 sx={style} />, <Filter3 sx={style} />, <Filter4 sx={style} />, <Filter5 sx={style} />, <Filter6 sx={style} />, <Filter7 sx={style} />, <Filter8 sx={style} />, <Filter9 sx={style} />, <Filter9Plus sx={style} />
    ]

    return (
        <Box sx={{bgcolor: 'white', width: '100%', height: '100vh', position: 'relative' }}>
            <Button
                sx={{fontSize: '10px', my: 1}}
                onClick ={hideLog}
            >
                back to daily logs
            </Button>
            <ImageList sx={{width: '100%', pt: 0, mt: 0}} cols={1}>
                <ImageListItem
                    component='button'
                    onClick={() => setDisplayCarousel(true)}
                >
                    <img src={images[0]} style={{width: '100%'}} />
                    <ImageListItemBar
                        position='bottom'
                        sx={{py: 3, px: 2}}
                        actionIcon={
                            imageIcons[images.length -1]
                        }
                    />
                </ImageListItem>
            </ImageList>

            <Grid container px={2}>
                <Grid item xs={2} my='auto'>
                    <Typography sx={{fontSize: '50px', color: '#0b56a4'}}>
                        {day}
                    </Typography>
                </Grid>
                <Grid item xs={10} my='auto'>
                    <Typography variant='subtitle2' sx={{fontWeight: 'bold', color: '#0b56a4'}}>{new Date(date).toLocaleDateString()}</Typography>
                    <Typography variant='subtitle2' sx={{fontWeight: 'bold', color: '#0b56a4'}}>
                        {`${days[new Date(date).getDay()]}, ${new Date(date).getHours()}:${new Date(date).getMinutes()}`}
                    </Typography>
                </Grid>
            </Grid>

            <Typography variant='subtitle1' sx={{fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.38)', px: 2}}>
                {`${user}`}
            </Typography>

            {title &&
                <Typography variant='h5' sx={{px: 2, pt: 3}}>{title}</Typography>
            }

            {notes &&
                <Box sx={{p: 2}}>
                    <Typography variant='body1'>{notes}</Typography>
                </Box>
            }

            {displayCarousel &&
                <>
                    <Box sx={{width: '100%', height: '100vh', position: 'absolute', top: 0, left: 0, bgcolor: 'black'}} />
                    <Button
                        sx={{fontSize: '10px', color: 'white', position: 'absolute', top: '10px', left: '10px', zIndex: 2}}
                        onClick={() => {
                            setDisplayCarousel(false);
                            setActiveIndex(0);
                        }}
                    >
                        back to daily log
                    </Button>
                    <SwipeableViews
                        style={{position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', zIndex: 2, width: '100%'}}
                        onChangeIndex={(step) => setActiveIndex(step)}
                    >
                        {images.map(image => (
                            <img 
                                src={image}
                                style={{width: '100%'}}
                            />
                        ))}
                    </SwipeableViews>
                    <Box sx={{width: '100%', position: 'absolute', bottom: '15px', textAlign: 'center'}}>
                        <span>
                            {images.map((image, index) => (
                                <Circle
                                    sx={{fontSize: '5px', color: activeIndex === index ? 'white' : 'gray', mr: 1,}}
                                />
                            ))}
                        </span>
                    </Box>
                </>
            }
        </Box>
    )

}

export default OpenLog;