import { CalendarMonth, Construction, LocationOn } from '@mui/icons-material';
import { Box, Button, Divider } from '@mui/material';
import React, { useContext } from 'react';
import InfoComp from './components/infoComp';
import { ProjectContext } from './openProject';

function ProjectInfo(props) {
    const { hideProject } = props;
    const projectContext = useContext(ProjectContext);
    const { project } = projectContext;
    const { salesToOps } = project;
    const { notes, projectDetails } = salesToOps;
    const { startDate, endDate, address, name } = projectDetails;

    return(
        <Box sx={{width: '100%', height: '100vh', bgcolor: 'white'}}>
            <Button
                sx={{fontSize: '12px', mt: 1, ml: 1}}
                onClick={hideProject}
            >
                back to project    
            </Button>

            <Box sx={{px: 3, mt: 1}}>
                <Divider />
                <svg width={0} height={0}>
                    <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
                        <stop offset={0} stopColor="#ffb000" />
                        <stop offset={.5} stopColor="#ed6a22" />
                        <stop offset={1} stopColor='#fd6f01' />
                    </linearGradient>
                </svg>
                <InfoComp
                    name='Project Name'
                    value={name}
                    icon={<Construction sx={{fill: 'url(#linearColors)'}} /> }

                />
                <InfoComp
                    name='Address'
                    value={address}
                    icon={<LocationOn sx={{fill: 'url(#linearColors)'}} /> }
                />
                <InfoComp
                    name='Project Dates'
                    value={`${new Date(startDate).toLocaleDateString()} - ${new Date(endDate).toLocaleDateString()}`}
                    icon={<CalendarMonth sx={{fill: 'url(#linearColors)'}} /> }
                />
                {/* <InfoComp
                    name='Notes from Sales'
                    value={notes}
                /> */}
                
            </Box>
        </Box>
    )

}

export default ProjectInfo;