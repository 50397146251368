import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { Box, Checkbox, Grid, Typography } from "@mui/material";
import { ProjectContext } from "../pages/openProject/openProject";

let newList = {
    date: new Date().toLocaleDateString(),
    onSite: false,
    client: false,
    sub: false,
    pictures: false,
    dailyLog: false,
}

function Checklist(props) {
    const { hideChecklist, index } = props;
    const projectContext = useContext(ProjectContext);
    const { project, setProject } = projectContext;
    const [checklist, setChecklist] = useState(project.checklist || []);
    const [loading, setLoading] = useState(true);
    const currentDate = new Date().toLocaleDateString();
    const [displayAlert, setDisplayAlert] = useState(false);

    const Row = (props) => {
        const { checked, row } = props;

        return (
            <Grid container>
                <Grid item xs={11}>{row.title}</Grid>
                <Grid item xs={1}>
                    <Checkbox checked={checked} onChange={() => save(row.col)} />
                </Grid>
            </Grid>
        )
    }

    const newRows = [
        {
            title: '30 Mins On Site',
            col: 'onSite',
            id: '123456789101112'
        },
        {
            title: 'Talked to Client',
            col: 'client',
            id: '223456789101112'
        },
        {
            title: 'Talked to Sub',
            col: 'sub',
            id: '323456789101112'
        },
        {
            title: 'Took Pictures',
            col: 'pictures',
            id: '423456789101112'
        },
        {
            title: 'Daily Log',
            col: 'dailyLog',
            id: '523456789101112'
        },
    ]
    
    function updateChecklist(list) {
        // update list in database
        axios.post(`https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/updateWithKey?containerId=projects&id=${project.id}&partitionKey=location`, {
            checklist: [...list]
        }).catch(err => {
            alert('Oops! There was an error updating the daily checklist. Please try again')
        }).then(res => {
            setChecklist([...list]);

            // why is this not updating checklist progress component?
            setProject({
                ...project,
                checklist: [...list]
            })
            //alert('A new checklist has been added for today!')
        })
    }

    useEffect(() => {
        let list = [];

        let start = new Date(project.salesToOps.projectDetails.startDate);
        start.setHours(0);

        // set hours to end of day (midnight) on last day of project
        let end = new Date(project.salesToOps.projectDetails.endDate);
        end.setHours(23);

        // create new empty checklist for current date if last checklist is not current
        if(checklist.length > 0 && checklist[0].date !== currentDate && new Date().getTime() < end.getTime()) {
            list = [newList, ...checklist];
            updateChecklist(list);
        }

        // create checklist if project has started and there is no checklist yet
        else if(checklist.length === 0 && new Date().getTime() >= start.getTime() && new Date().getTime() <= end.getTime()) {
            updateChecklist([newList])
        }

        else if(new Date().getTime() < start.getTime()) {
            setDisplayAlert(true);
        }

        // set loading to false
        setLoading(false);
    }, []);

    function save(field) {
        // update list locally
        let list = [...checklist];
        list[index] = {
            ...list[index],
            [field]: !list[index][field]
        }
        setChecklist(list);

        setProject({
            ...project,
            checklist: list
        })

        updateChecklist(list);
    }

    return (
        <Box sx={{p: 3}}>
            {checklist.length > 0 &&
                newRows.map(row => {
                    return(
                        <Row row={row} checked={checklist[index][row.col]} />
                    )
                })
            }
            {checklist.length === 0 &&
                <Typography variant='subtitle2' sx={{width: '100%', textAlign: 'center', mt: 2}}>No checklist to display.</Typography>
            }
        </Box>
    )
}

export default Checklist