import { Box, Button, Typography } from '@mui/material';
import React, { useContext } from 'react';
import InfoComp from './components/infoComp';
import { ProjectContext } from './openProject';

function SubInfo(props) {
    const { hideSub } = props;
    const projectContext = useContext(ProjectContext);
    const { project } = projectContext;
    const subcontractor = project.subcontractor || {};
    const { name, company, email, phone, address } = subcontractor;

    return(
        <Box sx={{width: '100%', height: '100vh', bgcolor: 'white'}}>
            <Button
                sx={{fontSize: '12px', mt: 1, ml: 1}}
                onClick={hideSub}
            >
                back to project    
            </Button>

            {name
                ?   <Box sx={{px: 3, mt: 3}}>
                        <InfoComp
                            name='Name'
                            value={name}
                        />
                        <InfoComp
                            name='Company'
                            value={company || ''}
                        />
                        <InfoComp
                            name='Address'
                            value={address || ''}
                        />
                        <InfoComp
                            name='Email'
                            value={email || ''}
                        />
                        <InfoComp
                            name='Phone Number'
                            value={phone || ''}
                        />
                        
                    </Box>
                :   <Typography variant='subtitle2' sx={{px: 3}}>No subcontractor information to display</Typography>
            }
        </Box>
    )

}

export default SubInfo;