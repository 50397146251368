import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import checkToken from '../utils/checkToken';
import { Box, Button, TextField, Autocomplete, MobileStepper, Typography, Select, MenuItem, Grid } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import ButtonBox from '../components/buttonBox/buttonBox';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

function NewProjectForm(props) {
    const types = ['Residential', 'Commercial', 'Government', 'Multifamily', 'Green', 'Snow']
    const theme = useTheme();
    const location = localStorage.getItem('location');
    const navigate = useNavigate();
    const { handleClose } = props;
    const [activeStep, setActiveStep] = useState(0);
    const [project, setProject] = useState({
        clientType: 'Residential',
        contact: {
            location: location
        },
        location: location,
        salesToOps: {
            projectManager: {}
        },
        documents: {},
        startDate: new Date(),
        endDate: new Date()
    });

    const [userEmails, setUserEmails] = useState([]);
    const [users, setUsers] = useState([]);

    function handleChange(e) {
        setProject({
            ...project,
            [e.target.id]: e.target.value
        })
    }

    function handleContactChange(e) {
        setProject({
            ...project,
            contact: {
                ...project.contact,
                [e.target.id]: e.target.value
            }
        })
    }
    
    async function getAllUsers() {
        const token = await checkToken();

        axios.get('https://graph.microsoft.com/v1.0/users?$top=999', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(res => {
            console.log(res.data)
            let emails = res.data.value.map(user => {
                if(typeof user.mail === 'string') return user.mail
                else return ''
            });
            setUserEmails([...emails]);
            setUsers(res.data.value);
        })
    }

    useEffect(() => {
        getAllUsers();
    }, []);

    
    function save() {
        console.log(project);

        // get the name of project manager
        const pm = users.filter(user => user.mail === project.salesToOps.projectManager.email)[0].displayName;
        project.salesToOps.projectManager.name = pm;

        axios.post(`https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/save?containerId=projects`, project).catch(err => alert(err)).then((res) => {
            const { id } = res.data;
            navigate(`/projects/${id}`);
        })
    }

    function handleNext() {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    function handleBack() {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }

    function handleDateChange(newValue, inputField) {
        inputField === 'start'
        ?   setProject({
            ...project,
            startDate: newValue
        })
        :   setProject({
            ...project,
            endDate: newValue
        })
    }

    return(
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            {activeStep === 0 &&
                <>
                    <Typography variant='h5' sx={{mb: 3}}>Client Info</Typography>
                    <TextField
                        sx={{mb: 2}}
                        size='small'
                        fullWidth
                        label="Client Name"
                        id='name'
                        onChange={handleContactChange}
                        value={project.contact.name || ''}
                    />
                    <TextField
                        sx={{mb: 3}}
                        size='small'
                        fullWidth
                        label="Client Phone"
                        id='phone'
                        onChange={handleContactChange}
                        value={project.contact.phone || ''}
                    />
                    <TextField
                        sx={{mb: 2}}
                        size='small'
                        fullWidth
                        label="Client Email"
                        id='email'
                        onChange={handleContactChange}
                        value={project.contact.email || ''}
                    />
                </>    
            }

            {activeStep === 1 &&
                <>
                    <Typography variant='h5' sx={{mb: 3}}>Project Info</Typography>
                    
                    <Select
                        fullWidth
                        value={project.clientType}
                        displayEmpty
                        size='small'
                        sx={{mb: 2}}
                        id='clientType'
                        onChange={(e) => setProject({
                            ...project,
                            clientType: e.target.value
                        })}
                    >
                        {types.map(type => (
                            <MenuItem value={type} key={type}>{type}</MenuItem>
                        ))}
                    </Select>
                    <TextField
                        sx={{mb: 2}}
                        size='small'
                        fullWidth
                        label="Project Name"
                        id='name'
                        value={project.name || ''}
                        onChange={handleChange}
                    />
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <DesktopDatePicker
                                size='small'
                                label="Select Start Date"
                                inputFormat="MM/dd/yyyy"
                                value={project.startDate}
                                onChange={(newValue) => handleDateChange(newValue, 'start')}
                                renderInput={(params) => <TextField {...params} size='small' />}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DesktopDatePicker
                                size='small'
                                label="Select End Date"
                                inputFormat="MM/dd/yyyy"
                                value={project.endDate}
                                onChange={(newValue) => handleDateChange(newValue, 'end')}
                                renderInput={(params) => <TextField {...params} size='small' />}
                            />
                        </Grid>
                    </Grid>
                    <TextField
                        sx={{my: 2}}
                        size='small'
                        fullWidth
                        label="Project Value"
                        id='bidTotal'
                        onChange={handleChange}
                        variant='outlined'
                        value={project.bidTotal || ''}
                    />
                    <TextField
                        sx={{mb: 2}}
                        size='small'
                        fullWidth
                        label="Address"
                        id='address'
                        onChange={handleContactChange}
                        value={project.contact.address || ''}
                    />
                    {userEmails.length > 0 && 
                        <Autocomplete
                            onChange={(e, newValue) => setProject({
                                ...project,
                                salesToOps: {
                                    projectManager: {
                                        email: newValue
                                    }
                                }
                            })}
                            size='small'
                            fullWidth
                            sx={{mb: 3}}
                            options={userEmails}
                            renderInput={(params) => <TextField {...params} label="Select Project Manager" />}
                        />
                    }
                </>
            }

            {activeStep === 2 &&
                <>
                    <ButtonBox>
                        <Button
                            sx={{fontSize: '10px', mr: 1}}
                            variant='contained'
                            color='error'
                            onClick={handleClose}
                        >
                            cancel
                        </Button>
                        <Button
                            sx={{fontSize: '10px'}}
                            variant='contained'
                            color='success'
                            onClick={save}
                        >
                            save project
                        </Button>
                    </ButtonBox>
                </>

            }

            <Box sx={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                <MobileStepper
                    variant="progress"
                    steps={3}
                    position="static"
                    activeStep={activeStep}
                    sx={{ maxWidth: 400, flexGrow: 1 }}
                    nextButton={
                        <Button size="small" onClick={handleNext} disabled={activeStep === 5}>
                        Next
                        {theme.direction === 'rtl' ? (
                            <KeyboardArrowLeft />
                        ) : (
                            <KeyboardArrowRight />
                        )}
                        </Button>
                    }
                    backButton={
                        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                        {theme.direction === 'rtl' ? (
                            <KeyboardArrowRight />
                        ) : (
                            <KeyboardArrowLeft />
                        )}
                        Back
                        </Button>
                    }
                />
            </Box>
        </LocalizationProvider>
    )
}

export default NewProjectForm;