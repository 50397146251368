import { Box, Grid, ImageList, ImageListItem, ImageListItemBar, ListSubheader, Paper, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { ProjectContext } from "./openProject";

function Images() {
    const projectContext = useContext(ProjectContext);
    const { project, setProject } = projectContext;
    const addlImages = project.addlImages || [];
    let logEntries = project.logEntries || [];
    const [images, setImages] = useState([]);
    let filteredLogs = logEntries.filter(entry => entry.images && entry.images.length > 0);


    useEffect(() => {
        if(logEntries.length > 0) {
            let arr = logEntries.filter(entry => entry.images && entry.images.length > 0);
            setImages(arr);
        }
    }, [])

    return(
        <>
            <Typography variant='h6' sx={{my: 2, px: 2}}>Images from daily logs</Typography>
            {filteredLogs.length > 0 
                ?   <Box sx={{px: 2}}>
                        <Paper elevation={0} sx={{px: 2, pt: 3, pb: 2}}>
                            <Grid container spacing={2}>
                                {filteredLogs.map(log => {
                                    if(log.images && log.images.length > 0) {
                                        return (
                                            <Grid item xs={6}>
                                                <ImageList sx={{ width: '100%', mb: 0, mt: 0}} cols={1}>
                                                    <ImageListItem key={log.images[0]}>
                                                        <img src={log.images[0]} />
                                                        <ImageListItemBar
                                                            title={new Date(log.date).toLocaleDateString()}
                                                            subtitle={<span>by: {log.user}</span>}
                                                            position="below"
                                                        />
                                                    </ImageListItem>
                                                </ImageList>
                                            </Grid>
                                        )
                                    }
                                    return ''
                                })}
                            </Grid>
                        </Paper>
                    </Box>
                :   <Typography variant='body2' sx={{px: 2}}>No images to display</Typography>
            }

            <Grid container px={2}>
                <Grid item xs={10}>
                    <Typography variant='h6' sx={{mb: 2, mt: 3}}>Additional Images</Typography>
                </Grid>
                <Grid item xs={2}>
                    
                </Grid>
            </Grid>
            {addlImages.length > 0 
                ?   <Box sx={{px: 2}}>
                        <Paper elevation={0} sx={{p: 1}}>
                            <ImageList sx={{ width: '100% '}}>
                                {addlImages.map(image => (
                                    <ImageListItem key={image}>
                                        <img src={image} />
                                    </ImageListItem>
                                ))}
                            </ImageList>
                        </Paper>
                    </Box>
                :   <Typography variant='body2' sx={{px: 2}}>No images to display</Typography>
            }
        </>
    )
}

export default Images;