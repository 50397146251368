import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Box, Tabs, Tab, Typography, Grid, IconButton } from '@mui/material';
import React, { useContext, useState } from 'react';
import Checklist from '../../forms/checklist';
import { ProjectContext } from './openProject';

function Checklists() {
    const projectContext = useContext(ProjectContext);
    const { project } = projectContext;
    const [checklist, setChecklist] = useState(project.checklist || []);
    const [value, setValue] = useState(0);

    let date = new Date().getTime();
    let endDate = new Date(project.salesToOps?.projectDetails?.endDate).getTime();

    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    function handlePast() {
        if(value < checklist.length) {
            setValue(value+1)
        }
    }

    function handleFuture() {
        if(value > 0) {
            setValue(value-1)
        }
    }

    return(
        <>
            {checklist.length > 0 &&
                <>
                    <Grid container mt={2} textAlign='center'>
                        <Grid item xs={4} my='auto'>
                            <IconButton
                                onClick={handlePast}
                                disabled={value === checklist.length - 1}
                                sx={{color: '#0b56a4'}}
                            >
                                <ChevronLeft />
                            </IconButton>
                        </Grid>
                        <Grid item xs={4} textAlign='center'  my='auto'>
                            <Typography variant='subtitle2' sx={{color: '#0b56a4', fontWeight: 'bold'}}>
                                {days[new Date(checklist[value].date).getDay()]}
                            </Typography>
                            <Typography variant='subtitle2' sx={{color: '#0b56a4', fontWeight: 'bold'}}>
                                {checklist[value].date}
                            </Typography>
                        </Grid>
                        <Grid item xs={4} my='auto'>
                            <IconButton
                                disabled={value === 0}
                                onClick={handleFuture}
                                sx={{color: '#0b56a4'}}
                            >
                                <ChevronRight />
                            </IconButton>
                        </Grid>
                    </Grid>

                    {date < endDate &&
                        <Checklist index={value} />
                    }

                    {date > endDate &&
                        <>
                            <Typography variant='subtitle2' sx={{width: '100%', textAlign: 'center', mt: 2}}>No checklist to display for today.</Typography>
                            <Typography variant='subtitle2' sx={{width: '100%', textAlign: 'center'}}>
                                Project ended on {new Date(project.salesToOps?.projectDetails?.endDate).toLocaleDateString()}
                            </Typography>
                        </>
                    }
                </>
            }
            
            {checklist.length === 0 &&
                <>
                    <Typography variant='subtitle2' sx={{width: '100%', textAlign: 'center', mt: 2}}>No checklist to display.</Typography>
                    <Typography variant='subtitle2' sx={{width: '100%', textAlign: 'center'}}>
                        Please check back on {new Date(project.salesToOps?.projectDetails?.startDate).toLocaleDateString()}
                    </Typography>
                </>
            }
        </>
    )
}

export default Checklists;