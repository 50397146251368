import { Circle } from '@mui/icons-material';
import { Box, Divider, Grid, Typography } from '@mui/material';
import React from 'react';

function InfoComp(props) {
    const { name, value, icon } = props;

    return (
        <>
            <Grid container>
                <Grid item xs={2} pt={0} my='auto'>
                    { icon }
                </Grid>
                <Grid item xs={10} my='auto'>
                    <Typography variant='subtitle2' sx={{color: 'rgba(0, 0, 0, .75)', fontWeight: 'bold', mb: '5px'}}>
                        {name.toUpperCase()}
                    </Typography>
                    <Typography variant='subtitle2' sx={{color: 'rgba(0, 0, 0, .6)'}}>
                        {value}
                    </Typography>
                </Grid>
            </Grid>

            <Divider sx={{my: 3}} />
        </>
    )
}

export default InfoComp;