import React from "react";
import { Button } from "@mui/material";

function Info(props) {
    const { displayClient, displayProject, displaySub } = props;

    return(
        <>
            <Button 
                sx={{ borderRadius: '0px 60px 60px 0px', bgcolor: 'white', color: '#ed6a22', width: '65%', justifyContent: 'flex-start'}}
                variant='contained'
                onClick={displayProject}
            >
                project information
            </Button>
            <Button 
                sx={{ borderRadius: '0px 60px 60px 0px', bgcolor: 'white', color: '#ed6a22', width: '65%', mt: 2, justifyContent: 'flex-start'}}
                variant='contained'
                onClick={displayClient}
            >
                client information
            </Button>
            <Button 
                sx={{ borderRadius: '0px 60px 60px 0px', bgcolor: 'white', color: '#ed6a22', width: '65%', mt: 2, justifyContent: 'flex-start'}}
                variant='contained'
                onClick={displaySub}
            >
                sub information
            </Button>
        </>
    )
}

export default Info;