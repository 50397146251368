import React, { useEffect, useState } from "react";
import axios from "axios";
import PageLayout from "../../components/pageLayout/pageLayout";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useNavigate } from "react-router-dom";
import { Box, Button, Modal, Paper, Typography, Grid, Card, CardMedia, CardContent } from "@mui/material";
import style from "../../styles/modal";
import NewProjectForm from "../../forms/newProject";
import { AcUnit, AddRoad, CleaningServices, Fence, Deck, ElectricCar, Construction, AutoAwesome, Pool, Grass, CalendarMonth, Person, AttachMoney, AccessTime } from "@mui/icons-material";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import asphalt from '../../images/asphalt.jpg';
import janitor from '../../images/janitor.jpg';
import deck from '../../images/deck.jpg';
import snow from '../../images/snow.jpg';
import solar from '../../images/solar.jpg';
import refresh from '../../images/refresh.jpg';
import pool from '../../images/pool.jpg';
import landscape from '../../images/landscape.jpg';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#3DBD32' : '#3DBD32',
  },
}));

function Projects() {
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const location = localStorage.getItem('location');
    let options = { style: 'currency', currency: 'USD' };

    const navigate = useNavigate();

    const [users, setUsers] = useState([]);

    let iconStyle = {
        color: 'white',
        fontSize: '80px',
        borderRadius: '10px',
        padding: '10px',
        position: 'absolute',
        left: '50%',
        transform: 'translate(-50%, 0)',
        top: '-60px'
    }

    const images = {
        deck: deck,
        fence: deck,
        asphalt: asphalt,
        janitorial: janitor,
        sweep: janitor,
        snow: snow,
        electric: solar,
        green: solar,
        ev: solar,
        enhancement: refresh,
        pool: pool,
        landscape: landscape,
        landscaping: landscape
    };

    useEffect(() => {
        axios.get(`https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/getall?containerId=projects`)
            .then(res => {
                setProjects(res.data.filter(project => project.location === location));
                setLoading(false);
            })
    }, []);

    const redirect = (id) => {
        navigate(`/projects/${id}`)
    }

    return(
        <>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={{...style, width: '90%', maxWidth: '600px', p: 3}}>
                   <NewProjectForm handleClose={handleClose} />
                </Box>
            </Modal>
            <PageLayout
                page='Projects'
            >
                <Box sx={{px: 2}}>
                    {projects.map(project => {
                         let image = <Construction />
                         let arr = Object.keys(images);
                         arr.forEach((item) => {
                             if(project.division.toLowerCase().indexOf(item) > -1) {
                                image = images[item]
                             }
                         });

                         let total = new Date(project.salesToOps?.projectDetails?.endDate).getTime() - new Date(project.salesToOps?.projectDetails?.startDate).getTime();

                         let remaining = (new Date(project.salesToOps?.projectDetails?.endDate).getTime() - new Date().getTime());
 
                         let status = 'Not Started';
 
                         let completed = new Date().getTime() - new Date(project.salesToOps?.projectDetails?.startDate).getTime();
 
                         let progress = Math.floor((completed / total) * 100);
                     
                         if(progress > 100) {
                             progress = 100;
                             status = 'Completed'
                         }
 
                         else if(progress < 0) {
                             progress = 0
                         }
 
                         else {
                             remaining = Math.floor(remaining / 1000 / 60 / 60 / 24);
                             status = `${remaining} Days Left`
                         }
 
                        return (
                            <Card 
                                sx={{width: '100%', my: 2}}
                            >
                                <Box 
                                    component='button'
                                    sx={{border:' 0px', bgcolor: 'transparent', width: '100%', p: 0}}
                                    onClick={() => redirect(project.id)}
                                >
                                    <Box sx={{position: 'relative'}}>
                                        <Box sx={{width: '100%', height: '100%', bgcolor: 'rgba(0,0,0,.3)', position: 'absolute', top: 0, left: 0}} />
                                        <CardMedia
                                            component='img'
                                            image={image}
                                            height='150'
                                        />
                                        <Typography sx={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)', width: '80%', textAlign: 'center', zIndex: 5, color: 'white', letterSpacing: '.03em'}} variant='h5'>{project.division}</Typography>
                                    </Box>

                                    <CardContent>
                                        <Box sx={{width: '100%', textAlign: 'left'}}>
                                            <Typography variant="h6">{project.name}</Typography>

                                            <Grid container>
                                                <Grid item xs={4}>
                                                    <Typography variant='subtitle2' sx={{mt: 2, color: 'rgba(0, 0, 0, 0.54)'}}>
                                                        Start Date:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Typography variant='subtitle2' sx={{mt: 2, color: 'rgba(0, 0, 0, 0.54)'}}>{new Date(project.salesToOps?.projectDetails?.startDate).toLocaleDateString()}</Typography>
                                                </Grid>
                                            </Grid>

                                            <Grid container>
                                                <Grid item xs={4}>
                                                    <Typography variant='subtitle2' sx={{mt: 1, color: 'rgba(0, 0, 0, 0.54)'}}>
                                                        End Date:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Typography variant='subtitle2' sx={{mt: 1, color: 'rgba(0, 0, 0, 0.54)'}}>{new Date(project.salesToOps?.projectDetails?.endDate).toLocaleDateString()}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box sx={{mt: 4}}>
                                            <Grid container>
                                                <Grid item xs={9}>
                                                    <Typography variant='body1' fontWeight='bold' mb={1} sx={{textAlign: 'left'}}>Progress</Typography>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Box sx={{textAlign: 'right', width: '100%'}}>
                                                        <Typography variant='body2' fontWeight='bold'>
                                                            {progress} %
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <BorderLinearProgress variant="determinate" value={progress} />
                                        </Box>

                                        <Box sx={{justifyContent: 'flex-end', display: 'flex', width: '100%', mt: 3}}>
                                            {status === 'Not Started' && 
                                                <Box 
                                                    sx={{
                                                        fontSize: '10px', 
                                                        border: '1px solid #ADADAD', 
                                                        color: '#ADADAD', 
                                                        borderRadius: '15px', 
                                                        px: 2, 
                                                        bgcolor: '#ADADAD21'
                                                    }}
                                                >
                                                    <AccessTime 
                                                        fontSize='small' 
                                                        sx={{verticalAlign: 'middle', mr: 1}} 
                                                    /> {status}
                                                </Box>
                                            }

                                            {status === 'Completed' && 
                                                <Box 
                                                    sx={{
                                                        fontSize: '10px', 
                                                        border: '1px solid #27E218', 
                                                        color: '#27E218', 
                                                        borderRadius: '15px', 
                                                        px: 2, 
                                                        bgcolor: '#27e21821'
                                                    }}
                                                >
                                                    <AccessTime 
                                                        fontSize='small' 
                                                        sx={{verticalAlign: 'middle', mr: 1}} 
                                                    /> {status}
                                                </Box>
                                            }
                                            {status !== 'Completed' && status !== 'Not Started' &&
                                                <Box 
                                                    sx={{
                                                        fontSize: '10px', 
                                                        border: '1px solid #E2B502', 
                                                        color: '#E2B502', 
                                                        borderRadius: '15px', 
                                                        px: 2, 
                                                        bgcolor: '#E2B50221'
                                                    }}
                                                >
                                                    <AccessTime 
                                                        fontSize='small' 
                                                        sx={{verticalAlign: 'middle', mr: 1}} 
                                                    /> {status}
                                                </Box>
                                            }
                                        </Box>
                                    </CardContent>
                                </Box>
                            </Card>
                        )
                    })}
                </Box>
                {/* <Box sx={{display: {xs: 'block', md: 'none'}, px: 2}}>
                    {projects.map(project => {
                        let icon = <Construction />
                        let arr = Object.keys(icons);
                        arr.forEach((item) => {
                            if(project.division.toLowerCase().indexOf(item) > -1) {
                                icon = icons[item]
                            }
                        })

                        let total = new Date(project.salesToOps.endDate).getTime() - new Date(project.salesToOps.startDate).getTime();

                        let remaining = (new Date(project.salesToOps.endDate).getTime() - new Date().getTime());

                        let status = 'Not Started';

                        let completed = new Date().getTime() - new Date(project.salesToOps.startDate).getTime();

                        let progress = Math.floor((completed / total) * 100);
                    
                        if(progress > 100) {
                            progress = 100;
                            status = 'Completed'
                        }

                        else if(progress < 0) {
                            progress = 0
                        }

                        else {
                            remaining = Math.floor(remaining / 1000 / 60 / 60 / 24);
                            status = `${remaining} Days Left`
                        }

                        return (
                            <Paper 
                                sx={{pt: 3, px: 4, my: 10, borderRadius: '10px'}}
                                key={project.id}
                            >
                                
                                <Box 
                                    sx={{position: 'relative', border: '0px', bgcolor: 'transparent', pb: 3}}
                                    component='button'
                                    onClick={() => redirect(project.id)}
                                >
                                    {icon}
                                    <Typography variant='body2' sx={{fontSize: '20px', width: '100%', textAlign: 'center', fontWeight: 'bold', mt: 6}}>{project.name}</Typography>
                                    <Typography variant='body2' sx={{fontSize: '13px', width: '100%', textAlign: 'center', color: '#7a7a7a', mt: 1}}>{project.division}</Typography>

                                    <Box sx={{mt: 4}}>
                                        <Grid container>
                                            <Grid item xs={9}>
                                                <Typography variant='body1' fontWeight='bold' mb={1} sx={{textAlign: 'left'}}>Progress</Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Box sx={{textAlign: 'right', width: '100%'}}>
                                                    <Typography variant='body2' fontWeight='bold'>
                                                        {progress} %
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <BorderLinearProgress variant="determinate" value={progress} />
                                    </Box>
                                    
                                    <Box sx={{justifyContent: 'flex-end', display: 'flex', width: '100%', mt: 3}}>
                                        {status === 'Not Started' && 
                                            <Box 
                                                sx={{
                                                    fontSize: '10px', 
                                                    border: '1px solid #ADADAD', 
                                                    color: '#ADADAD', 
                                                    borderRadius: '15px', 
                                                    px: 2, 
                                                    bgcolor: '#ADADAD21'
                                                }}
                                            >
                                                 <AccessTime 
                                                    fontSize='small' 
                                                    sx={{verticalAlign: 'middle', mr: 1}} 
                                                /> {status}
                                            </Box>
                                        }

                                        {status === 'Completed' && 
                                            <Box 
                                                sx={{
                                                    fontSize: '10px', 
                                                    border: '1px solid #27E218', 
                                                    color: '#27E218', 
                                                    borderRadius: '15px', 
                                                    px: 2, 
                                                    bgcolor: '#27e21821'
                                                }}
                                            >
                                                 <AccessTime 
                                                    fontSize='small' 
                                                    sx={{verticalAlign: 'middle', mr: 1}} 
                                                /> {status}
                                            </Box>
                                        }
                                        {status !== 'Completed' && status !== 'Not Started' &&
                                            <Box 
                                                sx={{
                                                    fontSize: '10px', 
                                                    border: '1px solid #E2B502', 
                                                    color: '#E2B502', 
                                                    borderRadius: '15px', 
                                                    px: 2, 
                                                    bgcolor: '#E2B50221'
                                                }}
                                            >
                                                 <AccessTime 
                                                    fontSize='small' 
                                                    sx={{verticalAlign: 'middle', mr: 1}} 
                                                /> {status}
                                            </Box>
                                        }
                                    </Box>
                                </Box>
                            </Paper>
                        )
                    })}
                </Box> */}
            </PageLayout>
        </>
    )
}

export default Projects;