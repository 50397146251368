import { Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { ProjectContext } from "./openProject";

import { styled } from '@mui/material/styles';
import CircularProgress, {
  circularProgressClasses,
} from '@mui/material/CircularProgress';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
  }));

function PercentComplete() {
    const projectContext = useContext(ProjectContext);
    const { project } = projectContext;
    const [percent, setPercent] = useState(0);
    const [activeStep, setActiveStep] = useState(0);


    useEffect(() => {
        let milestones = [...project.milestones];
        milestones = milestones.sort((a, b) => {
            let milestoneA = new Date(a.startDate).getTime();
            let milestoneB = new Date(b.startDate).getTime();
            if(milestoneA < milestoneB) return -1;
            if(milestoneA > milestoneB) return 1;
            return 0;
        });

        const incompleteArr = milestones.filter(milestone => !milestone.complete);
        if(incompleteArr.length > 0) {
            setActiveStep(incompleteArr[0].title);
        }

        else {
            setActiveStep(milestones[milestones.length - 1].title);
        }
    }, [])

    useEffect(() => {

        let total = new Date(project.salesToOps?.projectDetails?.endDate).getTime() - new Date(project.salesToOps?.projectDetails?.startDate).getTime();

        let remaining = (new Date(project.salesToOps.endDate).getTime() - new Date().getTime());

        let completed = new Date().getTime() - new Date(project.salesToOps?.projectDetails?.startDate).getTime();

        let progress = Math.floor((completed / total) * 100);

        if(progress < 0) progress = 0;
        else if(progress > 100) progress = 100;

        setPercent(progress);
    }, [])

    return (
        <>
            <Typography variant='body2' sx={{fontWeight: 'bold', mb: 1, mt: 2}}>{percent}% complete</Typography>
            <BorderLinearProgress variant="determinate" value={percent} />

            <Typography variant='body2' sx={{fontWeight: 'bold', mb: 1, mt: 3}}>Current milestone</Typography>
            <Typography variant='body2'>{activeStep}</Typography>
        </>
    )
}

export default PercentComplete;