import { Clear, Photo } from "@mui/icons-material";
import { Box, IconButton, Typography, Grid, TextField, ImageList, ImageListItem, ImageListItemBar, Button } from "@mui/material";
import React, { useContext, useState } from "react";
import axios from "axios";
import toArrayBuffer from "to-array-buffer";
import { v4 as uuidv4 } from 'uuid';
import { ProjectContext } from "../pages/openProject/openProject";

const { BlobServiceClient } = require('@azure/storage-blob');
const blobSasUrl = 'https://tbconnectstorage.blob.core.windows.net/?sv=2021-06-08&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-01-24T01:54:35Z&st=2023-01-23T17:54:35Z&spr=https&sig=LPkdkk9v3%2Bt8P01xUPfYVHFirO7A6wUWVTdiKK1N%2F4Y%3D';
const blobServiceClient = new BlobServiceClient(blobSasUrl);
const containerName = 'projectimages';
const containerClient = blobServiceClient.getContainerClient(containerName);

function DailyLogForm(props) {
    const user = localStorage.getItem('user');
    const projectContext = useContext(ProjectContext);
    const { project, setProject } = projectContext;
    const logEntries = project.logEntries || [];

    const { hideForm } = props;
    const [ newLog, setNewLog ] = useState({
        images: [],
        user: user
    });

    function handleImageChange(e) {
        e.preventDefault();

        let files = Array.from(e.target.files);
        let tempFileArr = [];

        files.forEach((file, index) => {
            let reader = new FileReader();
            
            reader.onload = () => {
                var arrBuff = toArrayBuffer(reader.result);
                tempFileArr.push({name: file.name, buffer: arrBuff, src: reader.result});

                // set image array and file array if at the end of the file array
                if(index === files.length - 1) {
                    console.log(tempFileArr)
                    setNewLog({
                        ...newLog,
                        images: [...tempFileArr, ...newLog.images]
                    })
                }
            }

            reader.readAsDataURL(file)
        });
    }

    function submit() {
        newLog.date = new Date();

        // save images to azure blob storage
        // update images in current entry to just their url
        if(newLog.images.length > 0) {
            let uuid = uuidv4();
           
            let urlArr = newLog.images.map(file => {
                if(file.name) {
                    return `https://tbconnectstorage.blob.core.windows.net/projectimages/${uuid}${file.name}`
                }
                return file
            });

            newLog.images.forEach(file => {
                if(file.name) {
                    const blockBlobClient = containerClient.getBlockBlobClient(`${uuid}${file.name}`);
                    blockBlobClient.uploadBrowserData(file.buffer);
                }
            })

            newLog.images = urlArr;
        }

        
        axios.post(`https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/updateWithKey?containerId=projects&id=${project.id}&partitionKey=location`, {
            logEntries: [newLog, ...logEntries]
        }).catch(err => {
            alert('Oops! There was an error saving daily log, please try again')
        }).then(res => {
            // update list of log entries locally
            // setLogEntries([newLog, ...logEntries]);

            // reset form values
            setNewLog({
                date: new Date(),
                notes: 'Add notes...',
                title: '',
                images: [],
                user: user
            })

            // hide the form
            hideForm();

            // update project context
            setProject({
                ...project,
                logEntries: [newLog, ...logEntries]
            });
        })
    }

    function removeImage(image) {
        let arr = newLog.images.filter(item => item !== image);
        setNewLog({
            ...newLog,
            images: arr
        })
    }

    function handleOpen() {

    }

    return (
        <Box
            sx={{width: '100%', minHeight: '100vh', bgcolor: 'white'}}
        >
            <Box sx={{width: '100%', bgcolor: '#f5f5f5'}}>
                <Grid container>
                    <Grid item xs={2} my='auto'>
                        <IconButton
                            onClick={hideForm}
                        >
                            <Clear />
                        </IconButton>
                    </Grid>
                    <Grid item xs={8} my='auto'>
                        <Typography sx={{textAlign: 'center', fontWeight: 'bold'}} variant='body2'>
                            Add Daily Log
                        </Typography>
                    </Grid>
                    <Grid item xs={2} my='auto'>
                        <Button
                            sx={{fontSize: '10px', p: 0, minWidth: '50px'}}
                            variant='contained'
                            onClick={submit}
                        >
                            save
                        </Button>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{p: 2, width: '100%'}}>
                <TextField
                    value={newLog.notes || ''}
                    fullWidth
                    label='Add Notes'
                    multiline
                    minRows={4}
                    onChange={(e) => setNewLog({
                        ...newLog,
                        notes: e.target.value
                    })}
                />

                <IconButton sx={{fontSize: '12px'}} component="label" color="primary">
                {" "}
                    <Photo sx={{mr: 1}} /> ADD IMAGES
                    <input 
                        type="file" 
                        hidden onChange={handleImageChange} 
                        multiple />
                </IconButton>

                {newLog.images && newLog.images.length > 0 &&
                    <ImageList sx={{ width: '100%' }} cols={newLog.images.length > 2 ? 2 : 1}>
                    {newLog.images.map((image, index) => (
                        <ImageListItem 
                            key={image.name || image}
                        >
                            <img
                                src={image.src || image}
                                srcSet={image.src || image}
                                loading="lazy"
                                onClick={() => handleOpen(image)}
                            />
                            <ImageListItemBar
                                sx={{
                                    background:
                                    'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                                    'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                                }}
                                position="top"
                                actionIcon={
                                    <IconButton
                                        sx={{ color: 'white' }}
                                        onClick={() => removeImage(image)}
                                    >
                                        <Clear />
                                    </IconButton>
                                }
                                actionPosition="right"
                            />
                        </ImageListItem>
                    ))}
                    </ImageList>
                }
            </Box>
        </Box>
    )
}

export default DailyLogForm;