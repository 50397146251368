import { Article, Clear } from "@mui/icons-material";
import { Grid, Box, IconButton } from "@mui/material";
import axios from "axios";
import React, { createContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PageHeader from "../../components/pageHeader/pageHeader";
import PageLayout from "../../components/pageLayout/pageLayout";
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import ChecklistProgress from "./checklistProgress";
import ClientInfo from "./clientInfo";
import QuickActions from "./quickActions";
import DailyLogForm from "../../forms/dailyLogForm";
import Slide from "@mui/material/Slide";
import BottomNav from './bottomNavigation';
import DailyLog from "./dailyLog";
import Checklist from "../../forms/checklist";
import Images from "./images";
import PercentComplete from "./percentComplete";
import Info from "./info";
import OpenLog from "./openLog";

import bg from '../../images/bg.png';
import AddImages from "../../forms/addImages";
import toArrayBuffer from 'to-array-buffer';
import Checklists from "./checklist";
import ProjectInfo from "./projectInfo";
import SubInfo from "./subInfo";

export const ProjectContext = createContext({
    project: {},
    setProject: () => {},
    imageArr: [],
    displayLog: '',
    setDisplayLog: '',
    activeLog: {}
})

function OpenProject() {
    const [project, setProject] = useState({});
    const { id } = useParams();
    const [value, setValue] = useState(0);
    const [displayForm, setDisplayForm] = useState(false);
    const [displayChecklist, setDisplayChecklist] = useState(false);
    const [displayImages, setDisplayImages] = useState(false);
    const [view, setView] = useState(0);
    const [imageArr, setImageArr] = useState([]);
    const [displayLog, setDisplayLog] = useState(false);
    const [activeLog, setActiveLog] = useState({});
    const [displayClient, setDisplayClient] = useState(false);
    const [displayProject, setDisplayProject] = useState(false);
    const [displaySub, setDisplaySub] = useState(false);
    const [endDate, setEndDate] = useState(null);
    const [startDate, setStartDate] = useState(null);

    const date = new Date().getTime();

    useEffect(() => {
        if(project.salesToOps?.projectDetails?.startDate && project.salesToOps?.projectDetails?.endDate) {
            let end = new Date(project.salesToOps?.projectDetails?.endDate).getTime();
            let start = new Date(project.salesToOps?.projectDetails?.startDate).getTime();
            setEndDate(end);
            setStartDate(start);
        }
    }, [project])

    useEffect(() => {
        axios.get(`https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/getById?containerId=projects&id=${id}`)
            .then(res => {
                console.log(res.data)
                setProject(res.data[0]);
            })
    }, [])

    useEffect(() => {
        // create first checklist if opens checklist tab after project has started and there are no checklists yet
        if(project.checklist && project.checklist.length === 0 && new Date().getTime() >= new Date(project.startDate).getTime()) {
            let newList = {
                date: new Date().toLocaleDateString(),
                onSite: false,
                client: false,
                sub: false,
                pictures: false,
                dailyLog: false,
            }

            axios.post(`https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/updateWithKey?containerId=projects&id=${project.id}&partitionKey=location`, {
                checklist: [newList]
            })
        }
    }, []);

    function handleFileChange(e) {
        e.preventDefault();
        let files = Array.from(e.target.files);
        let tempFileArr = [];

        files.forEach((file, index) => {
            const reader = new FileReader();
            
            reader.onload = () => {
                var arrBuff = toArrayBuffer(reader.result);
                tempFileArr.push({name: file.name, buffer: arrBuff, src: reader.result});

                // set image array and file array if at the end of the file array
                if(index === files.length - 1) {
                    console.log(tempFileArr)
                    setImageArr(tempFileArr);
                }
            }

            reader.readAsDataURL(file)
        });
    }

    useEffect(() => {
        if(imageArr.length > 0) {
            setDisplayImages(true);
        }
    }, [imageArr])

    return (
        <ProjectContext.Provider value={{
            project,
            setProject,
            imageArr,
            displayLog,
            setDisplayLog,
            activeLog,
            setActiveLog
        }}>
            <PageLayout
                page='Projects'
            >
                {Object.keys(project).length > 0 &&
                    <Box sx={{position: 'relative', width: '100%'}}>
                        <PageHeader
                            title={project.name}
                            icon={<Article /> }
                        />

                        {view === 0 &&
                            <>
                                {/* {project.milestones && project.milestones.length > 0 &&
                                    <Progress />
                                } */}
                                <Grid container spacing={2} px={2}>
                                    <Grid item xs={7}>
                                        <ChecklistProgress />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <PercentComplete />
                                    </Grid>
                                </Grid>

                                <Box sx={{position: 'relative'}}>
                                    <img src={bg} style={{width: '100%', position: 'relative'}} />
                                    <Box sx={{position: 'absolute', top: 0, left: 0, width: '100%'}}>
                                        <QuickActions 
                                            displayForm={() => setDisplayForm((prev) => !prev)} 
                                            displayChecklist={() => setDisplayChecklist(true)} 
                                            displayImages={handleFileChange}
                                        />
                                    </Box>
                                    <Box sx={{position: 'absolute', top: 210, left: 0, zIndex: 0}}>
                                        <Info 
                                            displayClient={() => setDisplayClient(true)}
                                            displayProject={() => setDisplayProject(true)}
                                            displaySub={() => setDisplaySub(true)}
                                        />
                                    </Box>
                                </Box>

                               

                                <Slide direction='up' in={displayForm} mountOnEnter unmountOnExit>
                                    <Box sx={{position: 'absolute', top: 0, zIndex: 5, width: '100%'}}>
                                        <DailyLogForm 
                                            hideForm={() => setDisplayForm(false)} 
                                        />
                                    </Box>
                                </Slide>


                                <Slide direction='up' in={displayChecklist} mountOnEnter unmountOnExit>
                                    <Box sx={{position: 'absolute', top: 0, zIndex: 5, width: '100%'}}>
                                        <Box
                                            sx={{width: '100%', height: '100vh', bgcolor: 'white'}}
                                        >
                                            <Box sx={{width: '100%', bgcolor: '#f5f5f5'}}>
                                                <Grid container>
                                                    <Grid item xs={2} my='auto'>
                                                        <IconButton
                                                            onClick={() => setDisplayChecklist(false)}
                                                        >
                                                            <Clear />
                                                        </IconButton>
                                                    </Grid>
                                                    <Grid item xs={8} my='auto'>
                                                        <Typography sx={{textAlign: 'center', fontWeight: 'bold'}} variant='body2'>
                                                            {new Date().toLocaleDateString()}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Box>

                                            {date < endDate && date > startDate &&
                                                <Checklist index={value} />
                                            }

                                            {date > endDate &&
                                                <>
                                                    <Typography variant='subtitle2' sx={{width: '100%', textAlign: 'center', mt: 2}}>No checklist to display for today.</Typography>
                                                    <Typography variant='subtitle2' sx={{width: '100%', textAlign: 'center'}}>
                                                        Project ended on {new Date(project.salesToOps?.projectDetails?.endDate).toLocaleDateString()}
                                                    </Typography>
                                                </>
                                            }

                                            {date < startDate &&
                                                <>
                                                    <Typography variant='subtitle2' sx={{width: '100%', textAlign: 'center', mt: 2}}>No checklist to display for today.</Typography>
                                                    <Typography variant='subtitle2' sx={{width: '100%', textAlign: 'center'}}>
                                                        Please check back on {new Date(project.salesToOps?.projectDetails?.startDate).toLocaleDateString()}
                                                    </Typography>
                                                </>
                                            }
                                        </Box>
                                    </Box>
                                </Slide>

                                <Slide direction='up' in={displayImages} mountOnEnter unmountOnExit>
                                    <Box sx={{position: 'absolute', top: 0, zIndex: 5, width: '100%'}}>
                                        <AddImages 
                                            hideImages={() => setDisplayImages(false)} 
                                        />
                                    </Box>
                                </Slide>

                                <Slide direction='left' in={displayClient} mountOnEnter unmountOnExit>
                                    <Box sx={{position: 'absolute', top: 0, zIndex: 5, width: '100%'}}>
                                        <ClientInfo
                                            hideClient={() => setDisplayClient(false)}
                                        />
                                    </Box>
                                </Slide>

                                <Slide direction='left' in={displayProject} mountOnEnter unmountOnExit>
                                    <Box sx={{position: 'absolute', top: 0, zIndex: 5, width: '100%'}}>
                                        <ProjectInfo
                                            hideProject={() => setDisplayProject(false)}
                                        />
                                    </Box>
                                </Slide>

                                <Slide direction='left' in={displaySub} mountOnEnter unmountOnExit>
                                    <Box sx={{position: 'absolute', top: 0, zIndex: 5, width: '100%'}}>
                                        <SubInfo
                                            hideSub={() => setDisplaySub(false)}
                                        />
                                    </Box>
                                </Slide>
                            </>
                        }

                        {view === 1 &&
                            <DailyLog />
                        }

                        {view === 2 &&
                            <Checklists />
                        }

                        {view === 3 &&
                            <Images />
                        }
                    </Box>
                }
                
            </PageLayout>
            <Paper sx={{position: 'fixed', bottom: 0, left: 0, right: 0}} elevation={3}>
                <BottomNav setView={setView} view={view} />
            </Paper>

            <Slide direction='left' in={displayLog} mountOnEnter unmountOnExit>
                <Box sx={{position: 'absolute', top: 0, left: 0, zIndex: 5, width: '100%'}}>
                    <OpenLog 
                        log={activeLog} 
                        hideLog={() => setDisplayLog(false)}
                    />
                </Box>
            </Slide>
        </ProjectContext.Provider>
    )
}

export default OpenProject