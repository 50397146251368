import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import React, { useState } from "react";
import { Check, Info, Notes, Photo } from "@mui/icons-material";

function BottomNav(props) {
    const { view, setView } = props;

    return(
        <BottomNavigation
            showLabels
            value={view}
            onChange={(e, newValue) => setView(newValue)}
            sx={{zIndex: 6}}
        >
            <BottomNavigationAction label='Project Info' icon={<Info />} />
            <BottomNavigationAction label='Daily Log' icon={<Notes />} />
            <BottomNavigationAction label='Check List' icon={<Check />} />
            <BottomNavigationAction label='Site Images' icon={<Photo />} />
        </BottomNavigation>
    )
}

export default BottomNav;