import { CameraAlt, Check, Notes } from '@mui/icons-material';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import React from 'react';
import bg from '../../images/bg.png';
import Info from './info';


function QuickActions(props) {
    const { displayForm, displayChecklist, displayImages } = props;

    return(
        <Grid container py={3}>
            <Grid item xs={4}>
                <Box
                    sx={{width: '100%', textAlign: 'center'}}
                >
                    <IconButton
                        sx={{bgcolor: '#0b56a4', color: 'white', mb: 1, boxShadow: '0 0 5px 3px rgba(0, 0, 0, .2)'}}
                        onClick={displayForm}
                    >
                        <Notes sx={{fontSize: '40px'}} />
                    </IconButton>
                    <Typography>Add Daily Log</Typography>
                </Box>
            </Grid>
            <Grid item xs={4}>
                <Box
                    sx={{width: '100%', textAlign: 'center'}}
                >
                    <IconButton
                        sx={{ mb: 1, color: 'white', bgcolor: '#0b56a4', boxShadow: '0 0 5px 3px rgba(0, 0, 0, .2)'}}
                        onClick={displayChecklist}
                    >
                        <Check sx={{fontSize: '40px'}} />
                    </IconButton>
                    <Typography>Today's Checklist</Typography>
                </Box>
            </Grid>
            <Grid item xs={4}>
                <Box
                    sx={{width: '100%', textAlign: 'center'}}
                >
                    <IconButton sx={{ mb: 1, color: 'white', bgcolor: '#0b56a4', boxShadow: '0 0 5px 3px rgba(0, 0, 0, .2)'}} component="label">
                    {" "}
                        <CameraAlt sx={{fontSize: '40px'}} />
                        <input 
                            type="file" 
                            hidden onChange={displayImages} 
                            multiple />
                    </IconButton>

                    <Typography>Take Picture</Typography>
                </Box>
            </Grid>
        </Grid>
    )
}

export default QuickActions;