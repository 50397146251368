import React, { useContext } from 'react';
import { ProjectContext } from './openProject';
import GoogleMapReact from 'google-map-react';
import { LocationOn } from '@mui/icons-material';
import { Box } from '@mui/material';

function Map() {
    const projectContext = useContext(ProjectContext);
    const { project } = projectContext;
    const { salesToOps } = project;
    const { coordinates } = salesToOps;
    const { lat, lng } = coordinates;

    return (
        <Box sx={{width: '100%', height: '500px'}}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: "AIzaSyA6OXdC0qAWY2JhTHhl--BaUnfzWBN9XH4" }}
                defaultCenter={[lat, lng]}
                defaultZoom={11}
            >
                <LocationOn
                    lat={lat}
                    lng={lng}
                    sx={{color: 'red', position: 'absolute'}}
                />
            </GoogleMapReact>
        </Box>
    )

}

export default Map;