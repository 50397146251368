import { Construction } from "@mui/icons-material";
import { Grid, Paper, Typography, Box, Divider } from "@mui/material";
import React, {useState} from "react";

function PageHeader(props) {
    const { title, icon, displaySearch, search, resetValues, children } = props;

    return(
        <Box sx={{width: '100%', px: 2, pb: 2}}>
            <svg width={0} height={0}>
                <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
                    <stop offset={0} stopColor="#ffb000" />
                    <stop offset={.5} stopColor="#ed6a22" />
                    <stop offset={1} stopColor='#fd6f01' />
                </linearGradient>
            </svg>
            <Grid container>
                <Grid item xs={2} my='auto'>
                    <Construction sx={{fill: 'url(#linearColors)'}} fontSize='large' />
                </Grid>
                <Grid item xs={10} my='auto'>
                    <Typography sx={{fontWeight: 500, letterSpacing: '.03em'}}>{title.toUpperCase()}</Typography>
                </Grid>
            </Grid>

            <Divider sx={{mt: 2}} />
       </Box>
    )
}

export default PageHeader;