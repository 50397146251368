import React, { useContext, useState } from 'react';
import { Box, Button, Grid, IconButton, ImageList, ImageListItem, ImageListItemBar, Typography } from '@mui/material';
import { Clear, Photo } from '@mui/icons-material';
import { ProjectContext } from '../pages/openProject/openProject';
import toArrayBuffer from 'to-array-buffer';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

const { BlobServiceClient } = require('@azure/storage-blob');
const blobSasUrl = 'https://tbconnectstorage.blob.core.windows.net/?sv=2021-06-08&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-01-24T01:54:35Z&st=2023-01-23T17:54:35Z&spr=https&sig=LPkdkk9v3%2Bt8P01xUPfYVHFirO7A6wUWVTdiKK1N%2F4Y%3D';
const blobServiceClient = new BlobServiceClient(blobSasUrl);
const containerName = 'projectimages';
const containerClient = blobServiceClient.getContainerClient(containerName);

function AddImages(props) {
    const projectContext = useContext(ProjectContext);
    const { imageArr, project, setProject } = projectContext;
    const { hideImages } = props;
    const [selected, setSelected] = useState(imageArr);

    function removeImage(item) {
        setSelected(selected.filter(image => image !== item));
        console.log(selected.filter(image => image !== item))
    }

    function handleImageChange(e) {
        e.preventDefault();

        let files = Array.from(e.target.files);
        let tempFileArr = [];

        files.forEach((file, index) => {
            let reader = new FileReader();
            
            reader.onload = () => {
                var arrBuff = toArrayBuffer(reader.result);
                tempFileArr.push({name: file.name, buffer: arrBuff, src: reader.result});

                // set image array and file array if at the end of the file array
                if(index === files.length - 1) {
                    setSelected([...selected, ...tempFileArr])
                }
            }

            reader.readAsDataURL(file)
        });
    }

    function save() {
        let uuid = uuidv4();
           
        let urlArr = selected.map(file => {
            if(file.name) {
                return `https://tbconnectstorage.blob.core.windows.net/projectimages/${uuid}${file.name}`
            }
            return file
        });

        selected.forEach(file => {
            if(file.name) {
                const blockBlobClient = containerClient.getBlockBlobClient(`${uuid}${file.name}`);
                blockBlobClient.uploadBrowserData(file.buffer);
            }
        })

        let arr = project.addlImages || [];

        axios.post(`https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/updateWithKey?containerId=projects&id=${project.id}&partitionKey=location`, {
            addlImages: [...arr, ...urlArr]
        }).catch(err => {
            alert('Oops! There was an error saving daily log, please try again')
        }).then(res => {
            // update list of log entries locally
            // setLogEntries([newLog, ...logEntries]);

            setSelected([]);

            // update project context
            setProject({
                ...project,
                addlImages: [...arr, ...urlArr]
            });

             // hide the form
             hideImages();
        })
    }

    return (
        <Box
            sx={{width: '100%', height: '100vh', bgcolor: 'white'}}
        >
            <Box sx={{width: '100%', bgcolor: '#f5f5f5'}}>
                <Grid container>
                    <Grid item xs={2} my='auto'>
                        <IconButton
                            onClick={hideImages}
                        >
                            <Clear />
                        </IconButton>
                    </Grid>
                    <Grid item xs={8} my='auto'>
                        <Typography sx={{textAlign: 'center', fontWeight: 'bold'}} variant='body2'>
                            Select images to add
                        </Typography>
                    </Grid>
                    <Grid item xs={2} my='auto'>
                        <Button
                            sx={{fontSize: '10px', p: 0, minWidth: '50px'}}
                            variant='contained'
                            onClick={save}
                        >
                            save
                        </Button>
                    </Grid>
                </Grid>
            </Box>

            <ImageList sx={{width: '100%', p: 2}}>
                {selected.map(item => (
                    <ImageListItem key={item.src}>
                        <img
                            src={item.src}
                        />
                        <ImageListItemBar
                            title=''
                            sx={{
                                background:
                                  'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                                  'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                              }}
                            actionIcon={
                                <IconButton
                                    onClick={() => removeImage(item)}
                                    //sx={{color: 'white'}}
                                >
                                    <Clear sx={{color: 'white'}} />
                                </IconButton>   
                            }
                            position='top'
                        />
                    </ImageListItem>
                ))}
            </ImageList>

            <IconButton sx={{fontSize: '12px'}} component="label" color="primary">
                {" "}
                <Photo sx={{mr: 1, ml: 2}} /> ADD MORE IMAGES
                <input 
                    type="file" 
                    hidden onChange={handleImageChange} 
                    multiple />
            </IconButton>
        </Box>
    )
}

export default AddImages;