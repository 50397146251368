import { AddCircle } from "@mui/icons-material";
import { Typography, List, ListItem, ListItemAvatar, Avatar, ListItemText, Slide, IconButton, Grid, Box } from "@mui/material";
import React, { useContext, useState } from "react";
import { ProjectContext } from "./openProject";
import DailyLogForm from "../../forms/dailyLogForm";
import OpenLog from "./openLog";

function DailyLog() {
    const projectContext = useContext(ProjectContext);
    const { project } = projectContext;
    const logEntries = project.logEntries || [];
    const [displayForm, setDisplayForm] = useState(false);
    const [activeLog, setActiveLog] = useState({});
    const [displayLog, setDisplayLog] = useState(false);

    function openLog(log) {
        setActiveLog(log);
        setDisplayLog(true);
    }

    return(
        <>
            <Grid container px={2}>
                <Grid item xs={10} my='auto'>
                    <Typography variant='h5'>Daily Log</Typography>
                </Grid>
                <Grid item xs={2} my='auto'>
                    <IconButton
                        onClick={() => setDisplayForm(true)}
                        color='success'
                    >
                        <AddCircle sx={{fontSize: '35px'}} />
                    </IconButton>
                </Grid>
            </Grid>
            <List>
            {logEntries.map(entry => (
                <Box sx={{px: 2}}>
                    <ListItem 
                        alignItems="flex-start" 
                        key={entry.date} 
                        sx={{bgcolor: 'white', mb: 2, border: '0px'}}
                        component='button'
                        onClick={() => openLog(entry)}
                    >
                        <ListItemAvatar>
                            <Avatar alt={entry.user} src='../images' sx={{bgcolor: '#ed6a22'}} />
                        </ListItemAvatar>
                        <ListItemText
                            primary={new Date(entry.date).toLocaleDateString()}
                                secondary={
                                <React.Fragment>
                                <Typography
                                    sx={{ display: 'inline' }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                >
                                    {entry.user}
                                </Typography>
                                {` — ${entry.notes.substring(0, 30)}…`}
                                </React.Fragment>
                            }
                        >
                        </ListItemText>
                    </ListItem>
                </Box>
                    
            ))}
            {logEntries.length === 0 &&
                <Typography variant='subtitle2' sx={{width: '100%', textAlign: 'center', mt: 2}}>There are no daily log entries to display</Typography>
            }
            </List>

            <Slide direction='up' in={displayForm} mountOnEnter unmountOnExit>
                <Box sx={{position: 'absolute', top: 0, zIndex: 5, width: '100%'}}>
                    <DailyLogForm 
                        hideForm={() => setDisplayForm(false)} 
                    />
                </Box>
            </Slide>

            <Slide direction='left' in={displayLog} mountOnEnter unmountOnExit>
                <Box sx={{position: 'absolute', top: 0, left: 0, zIndex: 5, width: '100%'}}>
                    <OpenLog 
                        log={activeLog} 
                        hideLog={() => setDisplayLog(false)}
                    />
                </Box>
            </Slide>
        </>
    )
}

export default DailyLog;