import React, { useContext, useEffect, useState } from 'react';
import { ProjectContext } from './openProject';
import { CircularProgress, Paper, Box, Typography, Grid, Divider } from '@mui/material';

function ChecklistProgress() {
    const projectContext = useContext(ProjectContext);
    const { project } = projectContext;
    const [variables, setVariables] = useState({});

    let totalNum = 0;
    if(project.checklist) {
        totalNum = project.checklist.length * 5
    }

    useEffect(() => {
        console.log('project updated')
    }, [project])

    useEffect(() => {
        getPercent()
    }, [project])

    function getPercent() {
        let total = 0;

        if(project.checklist) {
            project.checklist.forEach(checklist => {
                let num = Object.values(checklist).filter(value => value === true).length;
                if(num) {
                    total += num
                };
            });
        }

        let percent = ((total / totalNum) * 100);

        if(totalNum === 0 && total === 0) {
            setVariables({
                color: 'primary',
                progress: 0,
                completed: 0,
                text: 'Please make sure you are checking off items in the daily checklist.'
            });
        }
    
        else if(percent < 50) {
            setVariables({
                color: 'error',
                progress: percent,
                completed: total,
                text: 'Please make sure you are checking off items in the daily checklist.'
            });
        }

        else if(percent >= 50 && percent < 80) {
            setVariables({
                color: 'warning',
                progress: percent,
                completed: total,
                text: 'Please make sure you are checking off items in the daily checklist.'
            });
        }

        else {
            setVariables({
                color: 'success',
                progress: percent,
                completed: total,
                text: 'Please make sure you are checking off items in the daily checklist.',
                winning: true
            });
        }
    }

    return(
        <Paper sx={{mb: 2, p: 3, mt: 2}}>
            <Grid container>
                <Grid item xs={12} lg={3} my='auto'>
                    <Box sx={{width: '100%', textAlign: {
                        xs: 'center',
                        lg: 'left'
                    }}}>
                        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                            {Object.keys(variables).length > 0 &&
                                <CircularProgress 
                                    variant='determinate' 
                                    value={variables.progress}
                                    size={60}
                                    color={variables.color}
                                />
                            }
                            <Box
                                sx={{
                                    top: 0,
                                    left: 0,
                                    bottom: 0,
                                    right: 0,
                                    position: 'absolute',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <Typography
                                    variant="caption"
                                    component="div"
                                    color="text.secondary"
                                    sx={{fontWeight: 'bold'}}
                                >
                                    {`${Math.round(variables.progress)}%`}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} lg={9} my='auto'>
                    <Box sx={{width: '100%', textAlign: {
                        xs: 'center',
                        lg: 'left'
                    }, pl: {
                        md: 2
                    }}}>
                    <Typography variant='body2'>
                        <b>{variables.completed} / {totalNum}</b> checklist tasks completed so far
                        </Typography>
                        {/* <Divider sx={{my: 1}} /> */}
                        {/* <Typography variant='body2'>{variables.text}</Typography> */}
                        {variables.winning &&
                            <Typography component="div" variant='body2'>
                                You are <Box display="inline" sx={{fontWeight:'bold', color:'green'}}> WINNING</Box> this project!! Keep it up!
                            </Typography>
                        }
                    </Box>
                </Grid>
            </Grid>

        </Paper>
    )
}

export default ChecklistProgress;