import { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useClearCache } from 'react-clear-cache';

import Login from './pages/login/login';
import Projects from './pages/projects/projects';
import OpenProject from './pages/openProject/openProject';
// import Subcontractors from './pages/subcontractors/subcontractors';

import { LicenseInfo } from '@mui/x-license-pro';
LicenseInfo.setLicenseKey('f94999c7685f2608811fb8b48423f2baTz00ODIyNyxFPTE2OTA3Mzk3NTYzNjgsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=')

function App() {
  const { isLatestVersion, emptyCacheStorage } = useClearCache();

  useEffect(() => {
    if(!isLatestVersion) {
      emptyCacheStorage()
    }
  }, [])

  return (
    <Routes>
      <Route exact path='/' element={<Login />} />
      <Route exact path='/projects' element={<Projects />} />
      <Route exact path='/projects/:id' element={<OpenProject />} />
    </Routes>
  );
}

export default App;