import React from 'react';
import './login.css';
import LoginLogic from './loginLogic';
import logoSmall from '../../images/logoSmall.png';
import { Button, Divider, Modal, Typography, Box, Grid } from '@mui/material';
import style from '../../styles/modal';
import logoBig from '../../images/logoBig.png';

const Login = () => {
    const { login, open, redirect } = LoginLogic();

    return(
        <div className='login-bg'>
            <Modal
                open={open}
            >
                <Box sx={{...style, maxWidth: '300px', width: '90%', p:3}}>
                    <Typography mb={1} variant='h6' color='error'>WARNING!</Typography>
                    <Typography variant='body2'>You are about to use pre-released version of this software. This is for testing purposes only, some data may be deleted.</Typography>
                    <Typography variant='body2'></Typography>
                    <Box sx={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
                        <Button 
                            sx={{fontSize: '10px', mt: 2}} 
                            variant='contained'
                            onClick={redirect}
                        >
                            i accept
                        </Button>
                    </Box>
                </Box>
            </Modal>
            <div className='centered-component'>
                <img src={logoBig} alt='Transblue Logo' />
                <div className='login-wrapper'>
                    <h3 className='text-center'>Project Management</h3>
                    <Divider sx={{mt: 2, mb: 1, width: '75%', bgcolor: '#ed6a22'}} />
                    <Box sx={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
                        <Divider sx={{mb: 2, width: '75%', bgcolor: '#0b56a4', marginRight: '0px'}} />
                    </Box>
                    <p className='text-muted'>Press the login button below to log in to your Transblue account</p>
                    <Divider sx={{mt: 4, mb: 3}} />
                    <Button
                        onClick={login}
                        variant='contained'
                        fullWidth
                        sx={{bgcolor: '#ed6a22'}}
                    >
                        login
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default Login;